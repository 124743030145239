/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
				
				$( document ).ready(function() {
				// Get the header
				var header = document.getElementById("sub-header-nav");

				// Get the offset position of the navbar
				var sticky = header.offsetTop;
//				console.log('sticky = '+sticky);
//				console.log('window = '+window.pageYOffset);
				// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
				function myFunction() {
//					console.log('sticky = '+sticky);
//					console.log('window = '+window.pageYOffset);
					if (window.pageYOffset > sticky) {
						header.classList.add("sticky");
					} else {
						header.classList.remove("sticky");
					}
				}
					
				
				
				
				// When the user scrolls the page, execute myFunction
				window.onscroll = function() {myFunction();};
				});
				
				AOS.init({
					once: true,
					offset: 100,
					duration: 600,
					disable: 'mobile'
				});
				
				$(".woocommerce-product-gallery__image a").attr('data-lightbox', 'gallery');
				
				//console.log('ready!');
				
				$('.match-height').matchHeight();
				
        // JavaScript to be fired on all pages
				$('#responsiveTabs').responsiveTabs();
				$('.accordion').accordion({
						"transitionSpeed": 400
				});
				$( document ).ready(function() {
					$('.flexslider').flexslider({
						animation: "slide"
					});
				});
//				$(function(){
//					$('#menu-main-navigation').slicknav({
//						allowParentLinks:true,
//						prependTo:'.mobile-nav',
//						showChildren: true,
//						brand: '<a class="brand" href="http://127.0.0.1/bcs-theme/"><span></span></a>',
//					});
//				});
				
				$(function(){
					var combinedMenu = $('#menu-menu-1').clone();
					var secondMenu = $('.header-extra .menu').clone();
					//var thirdMenu = $('.social-nav').clone();
					secondMenu.children('li').appendTo(combinedMenu);
					//thirdMenu.children('li').appendTo(combinedMenu);
					combinedMenu.slicknav({
//						duplicate:false,
//						closedSymbol: '&#43;',
//						openedSymbol: '&#215;',
//						brand: '<a class="brand" href="http://www.rucsacs.com/"><span></span></a>',
//						label: "Menu"
						allowParentLinks:true,
						prependTo:'.mobile-nav',
						//showChildren: true,
						brand: '<a class="brand" href="http://127.0.0.1/scm/"></a>',
					});
				});
				
				// Remove Phantom Text Nodes
				function clean(node)
				{
					for(var n = 0; n < node.childNodes.length; n ++)
					{
						var child = node.childNodes[n];
						if( child.nodeType === 8 || (child.nodeType === 3 && !/\S/.test(child.nodeValue)) ) {
							node.removeChild(child);
							n --;
						} else if(child.nodeType === 1) {
							clean(child);
						}
					}
				}
				clean(document);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
				// Can also be used with $(document).ready()
				
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
